import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  UPDATE_PROFILE,
  IS_AUTHENTICATED,
  SET_USER_DETAIL,
  MANAGE_LOGIN_MODAL,
} from '../actions/types'

const initialState = {
  user: {},
  isAuthenticated: false,
  loginModal: false,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem('token', 'Bearer ' + action.payload.access_token)
      return {
        ...state,
        isAuthenticated: true,
      }

    case IS_AUTHENTICATED:
      return {
        isAuthenticated: action.payload,
        ...(action.payload === false && { user: {} }),
      }

    case SET_USER_DETAIL:
      return {
        user: action.payload,
        isAuthenticated: true,
      }

    case UPDATE_PROFILE:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      }

    case LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      }

    case LOGOUT:
      localStorage.clear()
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      }

    case MANAGE_LOGIN_MODAL:
      return {
        ...state,
        loginModal: action.payload,
      }

    default: {
      return state
    }
  }
}

export default authReducer
