import React, { useEffect, useState, useCallback } from 'react'
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormFloating,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CRow,
  CSpinner,
} from '@coreui/react'
import '../assets/scss/auth.scss'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { validateForm } from 'src/customize/helpers'
import { PostData } from 'src/apiHandler'
import PropTypes from 'prop-types'
import { LOADER_TOGGLE, LOGIN_SUCCESS, MANAGE_LOGIN_MODAL } from 'src/redux/actions/types'
import { LoginSocialGoogle, LoginSocialFacebook } from 'reactjs-social-login'

const LoginForm = ({ modal }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const REDIRECT_URI = window.location.href

  const isLoggedIn = useSelector((state) => state.auth.isAuthenticated)

  const [formData, setFormData] = useState({ email: '', password: '' })
  const [formError, setFormError] = useState({ email: '', password: '' })
  const [loader, setLoader] = useState(false)
  const [loginError, setLoginError] = useState('')
  //const [passwordShow, setPasswordShow] = useState(false)

  const [provider, setProvider] = useState('')
  const [profile, setProfile] = useState(null)
  const onLoginStart = useCallback(() => {
    console.log('login start')
  }, [])

  const onLogoutSuccess = useCallback(() => {
    setProfile(null)
    setProvider('')
    alert('logout success')
  }, [])

  const LoginHandler = () => {
    const check = validateForm(formData)
    if (check.success === false) {
      setFormError(check.data)
    } else {
      setLoader(true)
      setLoginError('')
      PostData('api/v1/customer/login', formData).then((response) => {
        setLoader(false)
        if (response.status === true) {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: { ...response.data },
          })
          if (modal) {
            dispatch({ type: MANAGE_LOGIN_MODAL, payload: false })
          } else {
            navigate('/')
          }
        } else {
          setLoginError(response.message)
        }
      })
    }
  }
  return (
    <CContainer className="form-i d-flex justify-content-center">
      <CForm className="form auth-form">
        <h1>Welcome Back</h1>
        <p className="text-medium-emphasis">Sign in below to access your account.</p>
        <CRow className="auth-btn-grp">
          {process.env.REACT_APP_GG_APP_ID ? (
            <LoginSocialGoogle
              isOnlyGetToken
              className="social-login"
              client_id={process.env.REACT_APP_GG_APP_ID || ''}
              onLoginStart={onLoginStart}
              onResolve={({ provider, data }) => {
                //setProvider(provider)
                //setProfile(data)
                console.log('data', data)
              }}
              onReject={(err) => {
                console.log(err)
              }}
            >
              <CButton className="auth-btn">
                <span>
                  <img src="/assets/icons/login-google.svg" alt="" />
                </span>
                Google
              </CButton>
            </LoginSocialGoogle>
          ) : null}
          {process.env.REACT_APP_FB_APP_ID ? (
            <LoginSocialFacebook
              isOnlyGetToken
              appId={process.env.REACT_APP_FB_APP_ID || ''}
              onLoginStart={onLoginStart}
              className="social-login"
              onResolve={({ provider, data }) => {
                //setProvider(provider)
                //setProfile(data)
                console.log('data', data)
              }}
              onReject={(err) => {
                console.log(err)
              }}
            >
              <CButton className="auth-btn">
                <span>
                  <img src="/assets/icons/login-f.svg" alt="" />
                </span>
                Facebook
              </CButton>
            </LoginSocialFacebook>
          ) : null}
        </CRow>
        <CRow className="or_b">
          <div className="line"></div>
          <div className="text">Or Continue With Email</div>
          <div className="line"></div>
        </CRow>
        <CRow className="form-f d-flex justify-content-center">
          {loginError ? (
            <div className="alert alert-danger" role="alert" style={{ width: '95%' }}>
              <b>Error : </b>
              {loginError}
            </div>
          ) : null}
          <CCol md={12}>
            <CFormFloating>
              <CFormInput
                placeholder="Username"
                autoComplete="username"
                value={formData.email}
                className="auth-textfield remove-left-border"
                id="email"
                check="email,blank"
                error="Please enter email"
                onChange={(e) => {
                  setLoginError('')
                  setFormData({ ...formData, email: e.target.value })
                  setFormError({ ...formError, email: '' })
                }}
              />
              <CFormLabel htmlFor="email">Username</CFormLabel>
            </CFormFloating>

            {formError.email ? (
              <div className="invalid-feedback d-block">{formError.email}</div>
            ) : null}
          </CCol>
          <CCol md={12}>
            <CFormFloating>
              <CFormInput
                type={'password'}
                placeholder="Password"
                value={formData.password}
                autoComplete="current-password"
                className="auth-textfield border-right-none password-field"
                id="password"
                check="blank,password"
                error="Please enter password"
                onChange={(e) => {
                  setLoginError('')
                  setFormData({ ...formData, password: e.target.value })
                  setFormError({ ...formError, password: '' })
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    LoginHandler()
                  }
                }}
              />
              <CFormLabel htmlFor="password">Password</CFormLabel>
            </CFormFloating>
            {formError.password ? (
              <div className="invalid-feedback d-block">{formError.password}</div>
            ) : null}
          </CCol>

          {/* <CInputGroupText className="border-left-none" onClick={showPassword}>
                {passwordShow ? (
                  <img
                    className="cursor-pointer"
                    src="/assets/images/password_show.svg"
                    alt="password show"
                  />
                ) : (
                  <img
                    className="cursor-pointer"
                    src="/assets/images/password_hide.svg"
                    alt="password hide"
                  />
                )}
              </CInputGroupText> */}
          <CInputGroup>
            <CButton onClick={loader ? null : LoginHandler}>
              {loader ? <CSpinner /> : 'Sign In'}
            </CButton>
          </CInputGroup>
          <p className="f_pass_link" onClick={() => navigate('/forgot-password')}>
            Forgot Password?
          </p>
          <p className="sign_up_t">
            Don’t have an account? <span onClick={() => navigate('/sign-up')}>Sign Up</span>
          </p>
        </CRow>
      </CForm>
    </CContainer>
  )
}

export default LoginForm

LoginForm.propTypes = {
  modal: PropTypes.object,
}
