import { createStore, combineReducers } from 'redux'
import authReducers from './reducers/authReducer'
import sidebarReducer from './reducers/sidebarReducer'
import toastReducer from './reducers/toastReducer'
import loaderReducer from './reducers/loaderReducer'
import modalReducer from './reducers/modalReducer'
import notificationReducer from './reducers/notificationReducer'
import filtersReducer from './reducers/filtersReducer'
import cmsReducer from './reducers/cmsReducer'
import cartReducer from './reducers/cartReducer'

const rootReducers = combineReducers({
  auth: authReducers,
  sidebar: sidebarReducer,
  toast: toastReducer,
  loader: loaderReducer,
  modal: modalReducer,
  webNotification: notificationReducer,
  filters: filtersReducer,
  cms: cmsReducer,
  cart: cartReducer,
})

const store = createStore(rootReducers)

export default store
