import React from 'react'
import moment from 'moment'
import { v1 as uuidv1 } from 'uuid'

export const getSlug = (title) => {
  return title.toLowerCase().replaceAll(' ', '-')
}

export const getCartTotal = (data) => {
  let totalPrice = 0
  data.map((product) => {
    totalPrice = totalPrice + product.variantInfo.price * product.quantity
    return null
  })
  return { totalPrice: formatPrice(totalPrice), totalPriceAfterDiscount: formatPrice(totalPrice) }
}

export const formatMobileNumber = (phoneNumberString) => {
  if (phoneNumberString.length < 10) {
    const mapObj = {
      sBracket: '',
      eBracket: '-',
      hiphen: '-',
    }
    const str = phoneNumberString.replace(
      /\b(?:sBracket|eBracket|hiphen)\b/gi,
      (matched) => mapObj[matched],
    )
    return str ? str : phoneNumberString
  }
  var cleaned = phoneNumberString.replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return isNaN(phoneNumberString) ? phoneNumberString.replace(/\D/g, '') : phoneNumberString
}

export const validateForm = (formData) => {
  const formErrorTemp = {}
  for (let obj in formData) {
    const currentObject = document.getElementById(obj)
    if (
      currentObject?.getAttribute('check')?.split(',').includes('blank') &&
      typeof formData[obj] !== 'number'
    ) {
      if (formData[obj] === '' || formData[obj] == null) {
        const errorString = currentObject?.getAttribute('error')
        formErrorTemp[obj] = errorString ? errorString : 'This field is required'
      }
    }
    if (currentObject?.getAttribute('check')?.split(',').includes('email')) {
      if (formData[obj] !== '' && !validateEmail(formData[obj])) {
        formErrorTemp[obj] = 'Please enter valid email address'
      }
    }
    if (currentObject?.getAttribute('check')?.split(',').includes('password')) {
      if (formData[obj] !== '' && validatePassword(formData[obj]).success === false) {
        formErrorTemp[obj] = validatePassword(formData[obj]).msg
      }
    }
    if (currentObject?.getAttribute('check')?.split(',').includes('phone')) {
      if (formData[obj] !== '' && formData[obj].replace(/\D/g, '').length !== 10) {
        formErrorTemp[obj] = 'Please enter 10 digit number'
      }
    }
    if (Object.keys(formData).includes('imei')) {
      if (formData[obj] !== '' && formData[obj].replace(/\D/g, '').length !== 15) {
        formErrorTemp[obj] = 'Please enter valid 15 digit number'
      }
    }
  }
  if (Object.values(formErrorTemp).length) {
    return { success: false, data: formErrorTemp }
  } else {
    return { success: true }
  }
}

const containsSpecialChars = (str) => {
  const specialChars = /[`!@#$%^&*()_+\-={};':"\\|,.<>?~]/
  return specialChars.test(str)
}

export const validatePassword = (password) => {
  const errorMessages = []
  if (password.length < 8) {
    errorMessages.push('Passwords must contain at least 8 characters')
  }
  if (/[A-Z]/.test(password) === false) {
    errorMessages.push('Passwords must contain at least 1 upper case letter')
  }
  if (/[a-z]/.test(password) === false) {
    errorMessages.push('Passwords must contain at least 1 lower case letter')
  }
  if (/[0-9]/.test(password) === false) {
    errorMessages.push('Passwords must contain at least 1 number')
  }
  if (containsSpecialChars(password) === false) {
    errorMessages.push('Passwords must contain at least 1 special character')
  }
  if (errorMessages.length) {
    return { success: false, msg: errorMessages[0] }
  }
  return { success: true }
}

export const validateEmail = (emailId) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,7})+$/.test(emailId)) {
    return true
  }
  return false
}

export const formatPrice = (price) => {
  if (price) {
    const currency = price < 0 ? '-$' : '$'
    const updatedPrice =
      currency +
      price
        .toLocaleString('en', {
          useGrouping: true,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .toString()
        .replace('-', '')
    return updatedPrice
  } else {
    return '$0'
  }
}

export const formatDate = (date) => {
  return moment(date).format('MMM DD, YYYY')
}

export const formateDateTime = (dateStr) => {
  return moment(dateStr).format('MMM D, YYYY, h:mm A')
}

export const searchFunction = (searchValue, dataSet, key) => {
  const filteredData = dataSet.filter((item) =>
    item[key].toString().toLowerCase().includes(searchValue.toString().toLowerCase()),
  )
  return filteredData
}

export const selectFunction = (type, keyFromData, dataToMatch, flagKey, data, isSelectAll) => {
  // all case: type, flagkey, data, isSelectAll is mandotory('all', null, null, 'status', [data], boolean)
  // ind case: type, keyFromData, dataToMatch, flagkey, data, is mandotory('all', 'key', 'key', 'status', [data])
  // reset case: type, keyFromData, dataToMatch, flagkey, data, is mandotory('remove', 'key', 'key', 'status', [data])
  let newData = data.map((item) => {
    if (type === 'all') {
      item[flagKey] = !isSelectAll
    } else if (type === 'reset') {
      item[flagKey] = false
    } else {
      if (item[keyFromData] === dataToMatch) {
        item[flagKey] = !item[flagKey]
      }
    }
    return item
  })
  let set = data.filter((item) => item[flagKey]).map((x) => x[keyFromData])
  return { updatedData: newData, selectedKeys: set }
}

export const getUniqueKey = () => {
  return uuidv1()
}

export const handleDownloadClick = (link) => {
  // Create a download link
  const element = document.createElement('a')
  element.setAttribute('href', link) // Set the path to your file
  element.setAttribute('target', '_blank')
  element.style.display = 'none'
  // Append the element to the document
  document.body.appendChild(element)
  // Trigger a click event on the element to start the download
  element.click()
  // Clean up the element
  document.body.removeChild(element)
}

export const getProductImage = (images = [], wrapper = false) => {
  if (Array.isArray(images)) {
    const primaryImage = images?.find((x) => x?.primary === true)
    return (
      <div className={wrapper ? 'product-image' : ''}>
        <img
          src={
            images?.length && primaryImage?.imageUrl
              ? primaryImage?.imageUrl
              : '/assets/images/default.jpg'
          }
          className="product-img"
          alt="product"
          onError={(e) => (e.target.src = '/assets/images/default.jpg')}
        />
      </div>
    )
  } else {
    return (
      <img
        src={images}
        alt="product"
        className="product-img"
        onError={(e) => (e.target.src = '/assets/images/default.jpg')}
      />
    )
  }
}

export const handleBeforeChange = (slide, total, name, arrowImg, inActiveArrow) => {
  if (document.querySelectorAll('.' + name + ' .slick-next').length) {
    const nextObj = document.querySelectorAll('.' + name + ' .slick-next')[0].firstElementChild
    const prevObj = document.querySelectorAll('.' + name + ' .slick-prev')[0].firstElementChild
    if (slide === parseInt(total)) {
      document.querySelectorAll('.' + name)[0].classList.add('blur-first-item')
      nextObj.src = inActiveArrow
      nextObj.style.transform = 'rotate(180deg)'
    } else {
      nextObj.src = arrowImg
      nextObj.style.transform = 'rotate(0)'
      if (total !== 0) {
        prevObj.src = arrowImg
        prevObj.style.transform = 'rotate(180deg)'
      }
      if (total === 0) {
        prevObj.src = inActiveArrow
        prevObj.style.transform = 'rotate(0)'
      }
      document.querySelectorAll('.' + name)[0].classList.remove('blur-first-item')
    }
  }
}
