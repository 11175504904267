import { ADD_TO_CART, UPDATE_CART_QUANTITY, DELETE_CART_PRODUCT } from '../actions/types'

const initialState = {
  products: localStorage.cart ? JSON.parse(localStorage.cart) : [],
}

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      const productId = action.payload.productId
      const variantId = action.payload.variantId
      const productIndex = state.products.findIndex(
        (x) => x.productId === productId && x.variantId === variantId,
      )
      if (productIndex === -1) {
        action.payload.quantity = 1
        state.products.push(action.payload)
      } else {
        state.products[productIndex].quantity++
      }
      localStorage.setItem('cart', JSON.stringify({ products: state.products }))
      return {
        ...state,
      }
    case UPDATE_CART_QUANTITY:
      const uproductId = action.payload.productId
      const uvariantId = action.payload.variantId
      const uproductIndex = state.products.findIndex(
        (x) => x.productId === uproductId && x.variantId === uvariantId,
      )
      if (action.payload.type === 'dec') {
        if (state.products[uproductIndex].quantity === 1) {
          state.products.splice(uproductIndex, 1)
        } else {
          state.products[uproductIndex].quantity--
        }
      } else {
        state.products[uproductIndex].quantity++
      }
      localStorage.setItem('cart', JSON.stringify(state.products))
      return {
        ...state,
      }
    case DELETE_CART_PRODUCT:
      const dproductId = action.payload.productId
      const dvariantId = action.payload.variantId
      const dproductIndex = state.products.findIndex(
        (x) => x.productId === dproductId && x.variantId === dvariantId,
      )
      state.products.splice(dproductIndex, 1)
      localStorage.setItem('cart', JSON.stringify(state.products))
      return {
        ...state,
      }
    default: {
      return state
    }
  }
}

export default cartReducer
