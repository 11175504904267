import React from 'react'
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react'
import { useSelector, useDispatch } from 'react-redux'
import LoginForm from '../LoginForm'
import { MANAGE_LOGIN_MODAL } from 'src/redux/actions/types'

const LoginModal = () => {
  const dispatch = useDispatch()
  const loginModal = useSelector((state) => state.auth.loginModal)
  const closeModal = () => {
    dispatch({ type: MANAGE_LOGIN_MODAL, payload: false })
  }
  return (
    <>
      <CModal
        visible={loginModal}
        onClose={() => closeModal()}
        aria-labelledby="loginFormModal"
        size="sm"
      >
        <CModalBody className="p-0">
          <div className="d-flex flex-row form-outer p-0 m-0 display-modal">
            <LoginForm modal={true} />
          </div>
        </CModalBody>
      </CModal>
    </>
  )
}

export default LoginModal
