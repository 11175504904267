import { SET_SIDEBAR, MANAGE_HEADER } from '../actions/types'
const initialState = {
  show: true,
  sidebarShow: false,
  showHeader: true,
}

const sidebarReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case SET_SIDEBAR:
      return { ...state, ...rest }
    case MANAGE_HEADER:
      return { ...state, ...rest }
    default:
      return state
  }
}

export default sidebarReducer
