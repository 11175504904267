import { WEB_NOTIFICATION } from '../actions/types'

const initialState = {
  sidebar: {
    totalOrders: 20,
    totalSoldOrders: 5,
    totalBuyOrders: 20,
  },
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case WEB_NOTIFICATION:
      return {
        ...state,
        ...action.payload,
      }
    default: {
      return state
    }
  }
}

export default notificationReducer
