import {
  SHOW_CONFIRMATION_MODAL,
  HIDE_CONFIRMATION_MODAL,
  SUBMIT_CONFIRMATION,
} from '../actions/types'

const initialState = {
  confirmationModal: false,
  submitConfirmation: false,
  heading: '',
  description: '',
}

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModal: true,
        submitConfirmation: false,
        ...action.payload,
      }
    case HIDE_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModal: false,
        submitConfirmation: false,
      }
    case SUBMIT_CONFIRMATION:
      return {
        ...state,
        submitConfirmation: true,
      }

    default: {
      return state
    }
  }
}

export default modalReducer
