import { SET_FILTERS_DATA } from '../actions/types'

const initialState = {
  filtersData: {},
}

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERS_DATA:
      return {
        ...state,
        filtersData: action.payload,
      }
    default:
      return state
  }
}

export default filtersReducer
