import {
  SET_CMS_DATA,
  CMS_DATA_CALLED,
  SET_COLLECTION_DATA,
  SET_HEADER_FOOTER,
  SET_DEVICE_TYPES,
} from '../actions/types'

const initialState = {
  cmsData: {},
  pages: [],
  collectionData: {},
  headerFooter: {},
  deviceTypes: [],
}

const cmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CMS_DATA:
      return {
        ...state,
        cmsData: action.payload,
      }
    case SET_HEADER_FOOTER:
      return {
        ...state,
        headerFooter: action.payload,
      }
    case SET_DEVICE_TYPES:
      return {
        ...state,
        deviceTypes: action.payload,
      }
    case CMS_DATA_CALLED:
      return {
        ...state,
        pages: [...state.pages, action.payload],
      }
    case SET_COLLECTION_DATA:
      return {
        ...state,
        collectionData: { ...state.collectionData, ...action.payload },
      }
    default: {
      return state
    }
  }
}

export default cmsReducer
