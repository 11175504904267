import React, { Suspense, useEffect, useRef, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CButton, CSpinner, CToast, CToastBody, CToastClose, CToaster } from '@coreui/react'
import { useDispatch, useSelector } from 'react-redux'
import { IS_AUTHENTICATED } from './redux/actions/types'
import LoginModal from './components/modals/LoginModal'
import useCommonData from './hooks/useCommonData'
import './assets/scss/style.scss'

const SuspenseLoader = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const Toasts = () => {
  const toastData = useSelector((state) => state.toast)
  const [toast, addToast] = useState(0)
  const toaster = useRef()

  useEffect(() => {
    const exampleToast = (
      <CToast
        autohide={true}
        color={toastData.settings.success ? 'success' : 'danger'}
        className="text-white align-items-center"
      >
        <div className="d-flex t-container">
          <img
            src={
              toastData.settings.success
                ? '/assets/icons/toast-tick.svg'
                : '/assets/icons/toast-error.svg'
            }
            alt="tick"
          />
          <div>
            <CToastBody className="toast-head">
              {toastData.settings.success ? 'Success' : 'Error'}
            </CToastBody>
            <CToastBody className="toast-message">{toastData.settings.message}</CToastBody>
          </div>
          <CToastClose component={CButton} color="secondary" size="sm" className="ms-1">
            <img src="/assets/icons/toast-close.svg" alt="tick" />
          </CToastClose>
        </div>
      </CToast>
    )
    if (toastData.show) {
      addToast(exampleToast)
    }
  }, [toastData])
  return (
    <>
      <CToaster ref={toaster} push={toast} placement="top-end" />
    </>
  )
}

const Loader = (show) => {
  const loader = useSelector((state) => state.loader.loaderShow)
  return (
    <div className={`loader-container ${loader === true || show === true ? '' : 'd-none'}`}>
      <CSpinner />
    </div>
  )
}

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ResetPassword = React.lazy(() => import('./views/pages/resetPassword/ResetPassword'))
const EmailRevisionForm = React.lazy(() =>
  import('./views/customerActionForms/EmailRevisionForm.js'),
)

const App = () => {
  useCommonData()
  const dispatch = useDispatch()
  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch({ type: IS_AUTHENTICATED, payload: true })
    } else {
      dispatch({ type: IS_AUTHENTICATED, payload: false })
    }
  }, [dispatch])

  return (
    <BrowserRouter>
      {/*fullScreenLoader ? (
        <div className="initial-loader">
          <CSpinner />
        </div>
      ) : null*/}
      <Suspense fallback={SuspenseLoader}>
        <Loader />
        <Toasts />
        <LoginModal />
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/create-password" name="Create Password" element={<ResetPassword />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route
            exact
            path="/revision-action"
            name="Customer Form"
            element={<EmailRevisionForm />}
          />
          <Route
            exact
            path="/returning-action"
            name="Customer Form"
            element={<EmailRevisionForm />}
          />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
