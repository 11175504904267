export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const IS_AUTHENTICATED = 'IS_AUTHENTICATED'
export const SET_USER_DETAIL = 'SET_USER_DETAIL'
export const MANAGE_LOGIN_MODAL = 'MANAGE_LOGIN_MODAL'

export const SET_CMS_DATA = 'SET_CMS_DATA'
export const CMS_DATA_CALLED = 'CMS_DATA_CALLED'
export const SET_COLLECTION_DATA = 'SET_COLLECTION_DATA'
export const SET_HEADER_FOOTER = 'SET_HEADER_FOOTER'
export const SET_DEVICE_TYPES = 'SET_DEVICE_TYPES'

export const LOGOUT = 'LOGOUT'

export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS'

export const LOADER_SHOW = 'LOADER_SHOW'
export const LOADER_HIDE = 'LOADER_HIDE'
export const LOADER_TOGGLE = 'LOADER_TOGGLE'
export const FULL_SCREEN_LOADER_TOGGLE = 'FULL_SCREEN_LOADER_TOGGLE'

export const SET_SIDEBAR = 'SET_SIDEBAR'
export const MANAGE_HEADER = 'MANAGE_HEADER'
export const TOAST_SHOW = 'TOAST_SHOW'
export const TOAST_HIDE = 'TOAST_HIDE'

export const SHOW_CONFIRMATION_MODAL = 'SHOW_CONFIRMATION_MODAL'
export const HIDE_CONFIRMATION_MODAL = 'HIDE_CONFIRMATION_MODAL'
export const SUBMIT_CONFIRMATION = 'SUBMIT_CONFIRMATION'

export const WEB_NOTIFICATION = 'WEB_NOTIFICATION'

export const SET_FILTERS_DATA = 'SET_FILTERS_DATA'

export const ADD_TO_CART = 'ADD_TO_CART'
export const UPDATE_CART_QUANTITY = 'UPDATE_CART_QUANTITY'
export const DELETE_CART_PRODUCT = 'DELETE_CART_PRODUCT'
