import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_USER_DETAIL, SET_HEADER_FOOTER, SET_DEVICE_TYPES } from 'src/redux/actions/types'
import { GetData } from 'src/apiHandler'

const useCommonData = () => {
  let token = localStorage.getItem('token')

  const dispatch = useDispatch()
  const { headerFooter, deviceTypes } = useSelector((state) => state.cms)
  const { user, isAuthenticated } = useSelector((state) => state.auth)

  const getUserProfile = useCallback(() => {
    if (token && isAuthenticated) {
      if (!user || Object.keys(user).length === 0) {
        GetData('api/v1/customer/customer-detail', token).then((response) => {
          if (response.status === true) {
            dispatch({ type: SET_USER_DETAIL, payload: response.data })
          }
          return response
        })
      }
    }
  }, [dispatch, token, user, isAuthenticated])

  const getHeaderFooterData = useCallback(() => {
    if (Object.keys(headerFooter).length === 0) {
      GetData('cms/v1/web-categories').then((response) => {
        if (response.status === true) {
          dispatch({
            type: SET_HEADER_FOOTER,
            payload: {
              header: response.data[0].categories,
              footer: response.data[1].categories,
            },
          })
        }
      })
    }
  }, [headerFooter])

  const getDeviceTypes = useCallback(() => {
    if (deviceTypes.length === 0) {
      GetData('common/v1/get-web-device-types').then((response) => {
        if (response.status === true) {
          dispatch({
            type: SET_DEVICE_TYPES,
            payload: response.data,
          })
        }
      })
    }
  }, [deviceTypes])

  useEffect(() => {
    getUserProfile()
  }, [getUserProfile])

  useEffect(() => {
    getHeaderFooterData()
  }, [getHeaderFooterData])

  useEffect(() => {
    getDeviceTypes()
  }, [getDeviceTypes])
}

export default useCommonData
